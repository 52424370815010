import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { ApiSettings } from '../../settings.class';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class EstimatesService {

  public currentCompanySurveys$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentSelectedSurvey$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public surveyApproveStatus$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public consensusValues$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) { }

  getCompanySurveys(companyId: number) {
    const params = new HttpParams()
      .set('q:company:id', `f:eq:${companyId}`)
      .set('order_by', 'created_at');
    return this.http.get(ApiSettings.BASE_URL + `/survey`, { params });
  }

  getCompanyAnalystList(companyId: number) {
    return this.http.get(ApiSettings.BASE_URL + `/company_analyst?q:company:id=${companyId}`).pipe(
      catchError(this.handleError),
      map(res => res['data'])
    );
  }
  deleteCompanyAnalyst(id: number) {
    return this.http.delete(ApiSettings.BASE_URL + '/company_analyst/'+id+'/').pipe(
      catchError(this.handleError)
    );
  }
  createCompanyAnalyst(companyAnalyst) {
    return this.http.post(ApiSettings.BASE_URL + '/company_analyst', companyAnalyst).pipe(
      catchError(this.handleError)
    );
  }
  getFirms() {
    return this.http.get(ApiSettings.BASE_URL + '/firm?page=1&order_by=updated_at').pipe(
      catchError(this.handleError),
      map(res => res['data'])
    );
  }

  getAnalysts(firmId: number) {
    return this.http.get(ApiSettings.BASE_URL + `/analyst?q:firm:id=${firmId}&page=1&order_by=updated_at`).pipe(
      catchError(this.handleError),
      map(res => res['data'])
    );
  }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return throwError(error);
  }

  setCurrentCompanySurveys(companySurveys: any[]) {
    this.currentCompanySurveys$.next(companySurveys)
  }

  getCurrentCompanySurveys() {
    return this.currentCompanySurveys$.asObservable();
  }

  // given company survey object return company survey name
  getCompanySurveyName(surveyObject) {
    return moment(surveyObject.date).format("YYYY-MM-DD").toString() ;
  }
  getSurvey(surveyId: number) {
    return this.http.get(ApiSettings.BASE_URL + `/survey/${surveyId}`).pipe(
      map(response => {
        this.setCurrentSelectedSurvey(response);
        return response;
      })
    );
  }
  setFormValue(data) {
    if(data || data === 0) {
      return data;
    }
    return null;
  }
  fetchLatestConsensus(surveyId: number) {
    return this.http.post(ApiSettings.BASE_URL + `/estimates/survey/${surveyId}/fetch_latest_consensus`, {})
  }
  getLatestSurveyDetails(companyId: number) {
    const params = new HttpParams()
      .set('q:company:id', `f:eq:${companyId}`)
      .set('page', '1')
      .set('order_by', 'date');
    return this.http.get(ApiSettings.BASE_URL + `/survey`, { params });
  }

  setCurrentSelectedSurvey(survey: any, updateConsensus = false) {
    this.currentSelectedSurvey$.next(survey);
    if(updateConsensus) {
      this.getConsenusData(survey.id).pipe(take(1)).subscribe();
    }
  }

  getCurrentSelectedSurvey() {
    return this.currentSelectedSurvey$.asObservable();
  }

  approveSurvey(payload: any): Observable<any> {
    return this.http.put(ApiSettings.BASE_URL + `/survey/${this.currentSelectedSurvey$.value.id}`, payload);
  }

  getCurrentSurveyApproveStatus() {
    return this.surveyApproveStatus$.asObservable();
  }

  setCurrentSurveyApproveStatus(status: boolean) {
    this.surveyApproveStatus$.next(status);
  }

  getConsenusData(surveyId: number) {
    return this.http.get(ApiSettings.BASE_URL + `/estimates/survey/${surveyId}/consensus`).pipe(
      map(res =>{
        this.setConsensusValues(res);
        return res;
      })
    );
  }
  
  getConsensusValues() {
    return this.consensusValues$.asObservable();
  }

  setConsensusValues(consensus: any) {
    this.consensusValues$.next(consensus);
  }
  
  toggleAPISync(toggleStatus: boolean, companyId: number) {
    return this.http.post(ApiSettings.BASE_URL + `/estimates/${companyId}/api_sync`, {fetch_from_collector: toggleStatus});
  }

  getAPISyncStatus(companyId: number) {
    const params = new HttpParams()
      .set('q:company:id', `f:eq:${companyId}`)
      .set('page', '1')
      .set('order_by', 'updated_at');
    return this.http.get<any[]>(`${ApiSettings.BASE_URL}/estimates_tier_isin`, {params})
      .pipe(
        map((response: any) => {
          if(response.data.length === 0) {
            return false;
          }
          const syncStatus = response.data.every(item => item.fetch_from_collector !== false);
          return syncStatus;
        })
      );
  }

  getAllEstimatesModules(): Observable<any> {
    return this.http.get(ApiSettings.BASE_URL + `/estimates/all_modules`).pipe(
      map((res: any) => res.map(item => ({
        name: item?.name,
        value: item
      }))),
      catchError(this.handleError)
    );
  }

  getStandardTaxonomyModules(moduleId: string): Observable<any> {
    return this.http.get(ApiSettings.BASE_URL + `/estimates/modules/${moduleId}`)
  }

  getTaxononyDetails(taxonomyId: number): Observable<any> {
    return this.http.get(ApiSettings.BASE_URL + `/estimates/taxonomy/${taxonomyId}`)
  }
  
  applyMultiplier(value: number, multiplier: number, isForDisplay = true) {
    if (value == null) return '';
    if (value === 0) return 0;
    multiplier = multiplier || 1;
    if(isForDisplay) {
      const convertedValue = (value / multiplier);
      return Number.parseFloat( convertedValue.toFixed(20) );
    } else{
      return +value * multiplier;
    }
  }

  flattenFormArrayValue(formArrayValue: any): any[] {
    let flattenedItems = [];
    formArrayValue.forEach((module) => {
      let item = module.item;
      flattenedItems.push(item);
      if (module.children && module.children.length > 0) {
        flattenedItems = flattenedItems.concat(this.flattenFormArrayValue(module.children));
      }
    });

    return flattenedItems
  }

  retainModuleLineIds(oldModules: any[], newModules: any[]) {
    newModules.forEach((newModule) => {
      const moduleToBeRetained = oldModules.find((oldModule) => oldModule.module_id === newModule.module_id && oldModule.type === newModule.type);
      if (moduleToBeRetained) {
        newModule.line_id = moduleToBeRetained.line_id;
      } else {
        if (newModule.hasOwnProperty('line_id')) {
          newModule.line_id = undefined
        }
      }
      if (newModule.children && newModule.children.length > 0) {
        this.retainModuleLineIds(oldModules, newModule.children);
      }
    });
  }
  
  findLineIdByModuleDetails(oldModules: any[], moduleDetails: any): number {
    const moduleToBeRetained = oldModules.find((oldModule) => oldModule.module_id === moduleDetails.module_id && oldModule.type === moduleDetails.type);
    return moduleToBeRetained?.line_id || undefined;
  }
  
  setPeriodDates(periodId: number, payload: any) {
    return this.http.post(ApiSettings.BASE_URL + `/estimates/survey_period/${periodId}/set_dates`, payload);
  }
  
  editCoverageList(surveyId: number, payload: any): Observable<any> {
    return this.http.patch(`${ApiSettings.BASE_URL}/estimates/survey/${surveyId}/coverage`, payload);
  }
  
  downloadExcelTemplate(surveyId: number) {
    return this.http.get(ApiSettings.BASE_URL + `/estimates/survey/${surveyId}/template`, { observe: 'response', responseType: 'arraybuffer'});
  }
  importExcel(surveyId: number, payload: any) {
    return this.http.post(ApiSettings.BASE_URL + `/estimates/survey/${surveyId}/import`, payload);
  }
  uploadFile(payload: FormData) {
    return this.http.post(ApiSettings.BASE_URL + `/${ApiSettings.TEMPORARY_STORAGE}`, payload, { reportProgress: true, observe: 'events' });
  }
}
